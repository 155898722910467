<template>
  <WeNavbar></WeNavbar>

  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2
        class="mt-6 text-center text-2xl font-semibold tracking-tight text-gray-700"
      >
        Portal de Rastreamento
      </h2>
      <p class="mt-2 text-center text-gray-700">
        Acompanhe o andamento da sua solicitação.
      </p>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-wepipe-box sm:rounded-lg sm:px-10">
        <form class="space-y-6">
          <div>
            <label for="code" class="block text-sm font-medium text-gray-700"
              >Código de Rastreamento</label
            >
            <div class="mt-1">
              <input
                ref="code"
                type="text"
                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-wepipe-blue focus:outline-none focus:wepipe-blue sm:text-sm"
                placeholder="Insira o código de rastreamento"
              />
            </div>
          </div>
          <div>
            <button
              @click="track()"
              type="button"
              class="group relative flex w-full justify-center rounded-md border border-transparent bg-wepipe-blue py-2 px-4 text-sm font-medium text-white hover:bg-wepipe-blue-hover focus:outline-none"
            >
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <font-awesome-icon icon="magnifying-glass" />
              </span>
              Rastrear
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <WeAlert
    ref="alert"
    title="Código Inválido"
    message="O código informado não é válido."
  ></WeAlert>
  <WeFooter></WeFooter>
</template>

<script>
import axios from "axios";
import WeAlert from "../components/WeAlert.vue";
import WeNavbar from "../components/WeNavbar.vue";
import WeFooter from "../components/WeFooter.vue";

export default {
  name: "FormView",
  components: {
    WeAlert,
    WeNavbar,
    WeFooter,
  },
  methods: {
    track() {
      axios
        .get("https://api.wepipe.com.br/tracker/" + this.$refs.code.value)
        .then((res) => {
          console.log(res);
          this.$router.push("timeline/" + this.$refs.code.value);
        })
        .catch((error) => {
          console.log(error);
          this.$refs.alert.openModal();
        });
    },
  },
};
</script>
