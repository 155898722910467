<template>
  <WeNavbar></WeNavbar>
  <div class="py-10">
    <header>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 class="text-2xl font-semibold text-gray-700">
          #{{ cardCode }} {{ pipeline }}
        </h1>
        <p class="mt-2 text-gray-700">
          Movimentado em:
          {{ moment(lastStageUpdatedAt).format("DD/MM/YYYY") }}
        </p>
      </div>
    </header>
  </div>
  <div
    class="flex min-h-full items-center justify-center py-10 px-4 sm:px-6 lg:px-8"
  >
    <div class="flow-root">
      <ul role="list" class="-mb-8">
        <li v-for="(event, eventIdx) in timeline" :key="event.id">
          <div class="relative pb-8">
            <span
              v-if="eventIdx !== timeline.length - 1"
              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
            <div class="relative flex space-x-3">
              <div v-if="event.total > 0">
                <span
                  class="bg-wepipe-blue h-8 w-8 rounded-full flex items-center justify-center text-white ring-8 ring-white"
                >
                  <font-awesome-icon icon="check" />
                </span>
              </div>
              <div v-else-if="event.stage_index == 9999">
                <span
                  class="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center text-white ring-8 ring-white"
                >
                  <font-awesome-icon icon="xmark" />
                </span>
              </div>
              <div v-else>
                <span
                  class="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center text-white ring-8 ring-white"
                >
                  <font-awesome-icon icon="clock" />
                </span>
              </div>
              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p class="text-sm font-semibold text-gray-700">
                    {{ event.stage }}
                    <a :href="event.href" class="font-medium text-gray-900">{{
                      event.target
                    }}</a>
                  </p>
                </div>
                <div class="whitespace-nowrap text-right text-sm text-gray-500">
                  <time v-if="event.total > 0">{{
                    moment(event.moved_at).format("DD/MM/YYYY")
                  }}</time>
                  <time v-else-if="event.stage_index == 9999"></time>
                  <time v-else>Aguardando</time>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <WeFooter></WeFooter>
</template>

<script>
import axios from "axios";
import moment from "moment";
import WeNavbar from "../components/WeNavbar.vue";
import WeFooter from "../components/WeFooter.vue";

export default {
  name: "TimelineView",
  components: {
    WeNavbar,
    WeFooter,
  },
  data() {
    return {
      moment: moment,
      cardCode: null,
      pipeline: null,
      timeline: {},
      lastStageUpdatedAt: null,
    };
  },
  created() {
    this.getTimeline();
  },
  methods: {
    getTimeline() {
      axios
        .get("https://api.wepipe.com.br/tracker/" + this.$route.params.code)
        .then((res) => {
          this.cardCode = this.$route.params.code;
          this.pipeline = res.data.pipeline;
          this.timeline = res.data.pipeline_stages;
          this.lastStageUpdatedAt = res.data.last_stage_updated_at;
        })
        .catch((error) => {
          this.$router.push("/");
          console.log(error.response.status);
        });
    },
  },
};
</script>
