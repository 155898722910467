<template>
  <Disclosure as="nav" class="bg-white border-b border-gray-200">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <a href="https://wepipe.com.br"
              ><img
                class="block h-8 w-auto lg:hidden"
                src="@/assets/wepipe-brand-light-bg.svg"
                alt="wepipe"
            /></a>
            <a href="https://wepipe.com.br"
              ><img
                class="hidden h-8 w-auto lg:block"
                src="@/assets/wepipe-brand-light-bg.svg"
                alt="wepipe"
            /></a>
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <a
              v-if="isTimeline"
              href="/"
              class="relative inline-flex items-center rounded-md border border-transparent bg-wepipe-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-wepipe-blue-hover focus:outline-none"
            >
              <font-awesome-icon icon="magnifying-glass" />
              <span class="pl-3">Rastrear</span>
            </a>
            <a
              v-else
              href="https://app.wepipe.com.br/wepipe/login"
              class="relative inline-flex items-center rounded-md border border-transparent bg-wepipe-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-wepipe-blue-hover focus:outline-none"
            >
              <font-awesome-icon icon="user" />
              <span class="pl-3">Login</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
</template>

<script>
import { Disclosure } from "@headlessui/vue";

export default {
  name: "WeNavbar",
  components: {
    Disclosure,
  },
  computed: {
    isTimeline() {
      return this.$route.name === "timeline";
    },
  },
};
</script>
