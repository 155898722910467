import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/style.css";
import "./registerServiceWorker";
import Router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faMagnifyingGlass,
  faCheck,
  faHourglassStart,
  faXmark,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUser);
library.add(faMagnifyingGlass);
library.add(faCheck);
library.add(faHourglassStart);
library.add(faXmark);
library.add(faClock);

createApp(App)
  .use(Router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
