import { createRouter, createWebHistory } from "vue-router";
import FormView from "../views/FormView.vue";
import TimelineView from "../views/TimelineView.vue";

const routes = [
  {
    path: "/",
    name: "form",
    component: FormView,
    meta: {
      title: "Portal de Rastreamento",
    },
  },
  {
    path: "/timeline/:code",
    name: "timeline",
    component: TimelineView,
    meta: {
      title: "Portal de Rastreamento",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
